import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layout';
import { H15 } from '../Texts';
import PrimaryLink from '../Buttons/PrimaryLink';

import contact from '../../images/get-in-touch.png';

const SuccessMessage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <div className="flex items-center">
        <div className="px-4 py-6 lg:pt-16 lg:pl-32 lg:w-7/12 mb-16 lg:flex-shrink-0">
          <H15 className="mb-3">
            {intl.formatMessage({ id: 'getInTouchSuccess.heading' })}
          </H15>
          <p className="mb-6">
            {intl.formatMessage({ id: 'getInTouchSuccess.text1' })}
          </p>
          <PrimaryLink
            text={intl.formatMessage({
              id: 'getInTouchSuccess.backToHome.text',
            })}
            to={intl.formatMessage({ id: 'getInTouchSuccess.backToHome.url' })}
          />
        </div>
        <div className="hidden lg:flex">
          <img src={contact} alt="contact" />
        </div>
      </div>
    </Layout>
  );
};

export default SuccessMessage;
